import React from 'react'
import './background.css'

function Background() {
  return (
    <div id='back-back'>
         <div className='c1'>
         </div>
         <div className='c2'>
         </div>
         <div className='c3'>
         </div>
         <div className='c4'>
         </div>
         <div className='c5'>
         </div>
         <div className='c6'>
         </div>
         <div className='c7'>
         </div>
         <div className='c8'>
         </div>
         <div className='c9'>
         </div>
         <div className='c10'>
         </div>
         <div className='c11'>
         </div>
         <div className='c12'>
         </div>
         <div className='c13'>
         </div>
         <div className='c14'>
         </div>
         <div className='c15'>
         </div>
         
    </div>
  )
}

export default Background