
import int1 from '../../assets/bulb_icon.svg'
import int2 from '../../assets/pen_icon.svg'
import int3 from '../../assets/headphone_icon.svg'
import int4 from '../../assets/camera_icon.svg'
const interests=[
    {
        imgsrc:int1,
        title:"Problem Solving"

    },
    {
        imgsrc:int2,
        title:"Drawing"

    },
    {
        imgsrc:int3,
        title:"Music"

    },
    {
        imgsrc:int4,
        title:"Movies"

    },
]

export default interests